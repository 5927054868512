import company from 'app/entities/company/company.reducer';
import branchMaster from 'app/entities/branch-master/branch-master.reducer';
import facilityWarehouse from 'app/entities/facility-warehouse/facility-warehouse.reducer';
import receipt from 'app/entities/receipt/receipt.reducer';
import artikeltexteDID from 'app/entities/artikeltexte-did/artikeltexte-did.reducer';
import dataIdentifierSSC from 'app/entities/data-identifier-ssc/data-identifier-ssc.reducer';
import deployment from 'app/entities/deployment/deployment.reducer';
import deploymentAdministration from 'app/entities/deployment-administration/deployment-administration.reducer';
import transportOrder from 'app/entities/transport-order/transport-order.reducer';
import vehicle from 'app/entities/vehicle/vehicle.reducer';
import nVE from 'app/entities/nve/nve.reducer';
import nVEInhaltWareneingang from 'app/entities/nve-inhalt-wareneingang/nve-inhalt-wareneingang.reducer';
import nVEInhaltWarenausgang from 'app/entities/nve-inhalt-warenausgang/nve-inhalt-warenausgang.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  company,
  branchMaster,
  facilityWarehouse,
  receipt,
  artikeltexteDID,
  dataIdentifierSSC,
  deployment,
  deploymentAdministration,
  transportOrder,
  vehicle,
  nVE,
  nVEInhaltWareneingang,
  nVEInhaltWarenausgang,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
